<template>
  <div class="home">
    <!-- banner -->
    <c-banner :type="'home'" :bannerHeight="'838px'">
      <!-- homeSearch -->
      <div class="searchBox" slot="searchBox">
        <ul>
          <li class="act">数字资源检索</li>
          <li
            @click="
              goOutLink(
                'https://opac.whlib.org.cn/opac/search?hasholding=1&rows=10&scWay=dim&searchSource=reader&searchWay=title&sortOrder=desc&sortWay=score&q='
              )
            "
          >
            馆藏检索
          </li>
        </ul>
        <div>
          <img src="@/assets/imgs/banner/input_sousuo_ico.png" alt="" />
          <input type="text" placeholder="请输入搜索内容" v-model="searchVal" />
          <button @click="goLibraryPc">搜索</button>
        </div>
      </div>
      <!-- homeSideNav -->
      <ul class="sideNav" slot="sideNav">
        <li v-for="(item, index) of sideNav" :key="index" @click="goPage(item)">
          <img :src="item.ico" alt="" />
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </c-banner>
    <news />
    <!-- fhydfhfdh -->
    <newBook />
    <lecture />
    <exhibition />
  </div>
</template>

<script>
import cBanner from "@/components/c-banner";
import news from "@/containers/home/news";
import newBook from "@/containers/home/newBook";
import lecture from "@/containers/home/lecture";
import exhibition from "@/containers/home/exhibition";
export default {
  components: {
    cBanner,
    news,
    newBook,
    lecture,
    exhibition,
  },
  data() {
    return {
      sideNav: [
        {
          name: "书目查询",
          ico: require("@/assets/imgs/banner/sousuo.png"),
          type: "out",
          link: "https://opac.whlib.org.cn/opac/index",
        },
        {
          name: "书目分类",
          ico: require("@/assets/imgs/banner/fenlei.png"),
          type: "out",
          link: "https://opac.whlib.org.cn/opac/browse/cls",
        },
        {
          name: "网上办证",
          ico: require("@/assets/imgs/banner/zhengjian.png"),
          type: "out",
          link: "https://opac.whlib.org.cn/opac/reader/register",
        },
        {
          name: "我的借阅",
          ico: require("@/assets/imgs/banner/jieyue.png"),
          type: "out",
          link: "https://opac.whlib.org.cn/opac/reader/login?returnUrl=/loan/renewList",
        },
        {
          name: "我的续借",
          ico: require("@/assets/imgs/banner/xujie.png"),
          type: "out",
          link: "https://opac.whlib.org.cn/opac/reader/login?returnUrl=/loan/renewList",
        },
        {
          name: "常见问题",
          ico: require("@/assets/imgs/banner/wenti.png"),
          type: "",
          link: "/consult",
        },
      ],
      searchVal: "",
    };
  },
  methods: {
    goLibraryPc() {
      let that = this;
      let staticData = {
        click: true,
        pv: false,
        search: false,
      };
      that.addStatic(staticData);
      if (!that.trim(that.searchVal)) return false;
      // window.open("http://192.168.2.14/?dept=MzI1&searchVal=" + that.searchVal)
      window.open(
        "https://library.yuntuys.com/?dept=MzI1&searchVal=" + that.searchVal
      );
    },
    goPage(item) {
      let that = this;

      let staticData = {
        click: true,
        pv: false,
        search: false,
      };
      that.addStatic(staticData);

      if (!item.type) {
        // that.$router.push({
        //   name: 'problemList'
        // })
        that.$router.push({
          path: item.link + "?id=4",
        });
      } else {
        window.open(item.link);
      }
    },

    goOutLink(link) {
      let that = this;
      let staticData = {
        click: true,
        pv: false,
        search: false,
      };
      that.addStatic(staticData);
      window.open(link);
    },
    addStatic(data) {
      this.api.statisticsSave(data).then((res) => {});
    },
    // 去除前后空格
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  overflow: hidden;
  padding-bottom: 120px;
}
</style>