<template>
    <div class="putQuestionsMethod">
        <img src="../../assets/imgs/consult/putQuestionsMethod1.png" alt="">
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    mounted() {
        
    },
    methods: {
        
    }
};
</script>
<style lang="scss" scoped>
    .putQuestionsMethod{
        width: 100%;
        background: #fff;
        padding: 40px;
        >img{
            display: block;
            width: 100%;
        }
    }
</style>