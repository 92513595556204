<template>
  <div class="container">
    <c-banner :type="'about'" :bannerHeight="'600px'" />
    <!-- 概况 -->
    <div class="survey">
      <img src="@/assets/imgs/about/surveyLeft.png" alt="" />
      <div>
        <img src="@/assets/imgs/about/surveyInfo.png" alt="" />
      </div>
    </div>
    <!-- 地形 -->
    <div class="terrain">
      <div>
        <div>
          <img src="@/assets/imgs/home/moreHead_titleIco.png" alt="" />
          <h4>地形分布</h4>
        </div>
        <div>
          <ul>
            <template v-for="item of terrain">
              <li
                :key="item.id"
                @mouseenter="terrainSelect(item)"
                :class="item.cover == actTerrain ? 'act' : ''"
              >
                <p>{{ item.name }}</p>
                <div></div>
              </li>
            </template>
          </ul>
          <img :src="actTerrain" alt="" />
        </div>
      </div>
    </div>
    <!-- 掠影 -->
    <div class="glimpse">
      <h3>馆舍掠影</h3>
      <ul>
        <li
          v-for="(item, index) of glimpse"
          :key="item.id"
          @click="glimpseSelected(index)"
          :class="actGlimpseIdx == index ? 'act' : ''"
        >
          {{ item.name }}
        </li>
      </ul>
      <div>
        <swiper
          ref="topSwiper"
          v-if="isUpdate"
          :options="swiperTop"
          class="topSwiper"
        >
          <swiper-slide
            v-for="(item, index) of glimpse[actGlimpseIdx].imgs"
            :key="index"
            class="topSwiperSlide"
          >
            <img :src="item.url" alt="" />
            <!-- <p>{{item.id}}</p> -->
          </swiper-slide>
        </swiper>
        <div class="bottomSwiperBox">
          <div class="swiper-button-prev prev"></div>
          <swiper
            ref="bottomSwiper"
            v-if="isUpdate"
            :options="swiperBottom"
            class="bottomSwiper"
          >
            <swiper-slide
              v-for="(item, index) of glimpse[actGlimpseIdx].imgs"
              :key="index"
              class="bottomSwiperSlide"
            >
              <img :src="item.url" alt="" />
              <!-- <p>{{item.id}}</p> -->
            </swiper-slide>
          </swiper>
          <div class="swiper-button-next next"></div>
        </div>
      </div>
    </div>
    <!-- 开放时间 -->
    <!-- <div class="openTime">
            <div>
                <div>
                    <img src="@/assets/imgs/about/headIco.png" alt="">
                    <h4>开放时间</h4>
                </div>
                <img src="@/assets/imgs/about/timeInfo.png" alt="">
            </div>
        </div> -->
    <!-- 交通指南 -->
    <div class="trafficGuide">
      <div>
        <div>
          <img src="@/assets/imgs/home/moreHead_titleIco.png" alt="" />
          <h4>交通指南</h4>
        </div>
        <img src="@/assets/imgs/about/trafficGuideInfo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import cBanner from "@/components/c-banner";
export default {
  components: {
    cBanner,
  },
  data() {
    return {
      terrain: [
        {
          id: 1,
          name: "一层平面布置图",
          cover: require("@/assets/imgs/about/terrain/1.jpg"),
        },
        {
          id: 2,
          name: "二层平面布置图",
          cover: require("@/assets/imgs/about/terrain/2.jpg"),
        },
        {
          id: 3,
          name: "三层平面布置图",
          cover: require("@/assets/imgs/about/terrain/3.jpg"),
        },
        {
          id: 4,
          name: "七层平面布置图",
          cover: require("@/assets/imgs/about/terrain/7.jpg"),
        },
      ],
      actTerrain: require("@/assets/imgs/about/terrain/1.jpg"),
      glimpse: [
        {
          id: 1,
          name: "电子阅览室",
          imgs: [
            {
              url: require("@/assets/imgs/about/glimpse/1/1.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/1/2.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/1/3.jpeg"),
            },
          ],
        },
        {
          id: 2,
          name: "多功能室",
          imgs: [
            {
              url: require("@/assets/imgs/about/glimpse/2/1.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/2/2.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/2/3.jpeg"),
            },
          ],
        },
        {
          id: 3,
          name: "成人图书外借室",
          imgs: [
            {
              url: require("@/assets/imgs/about/glimpse/3/1.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/3/2.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/3/3.jpeg"),
            },
          ],
        },
        {
          id: 4,
          name: "少儿综合阅览室",
          imgs: [
            {
              url: require("@/assets/imgs/about/glimpse/4/1.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/4/2.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/4/3.jpeg"),
            },
          ],
        },
        {
          id: 5,
          name: "期刊阅览室",
          imgs: [
            {
              url: require("@/assets/imgs/about/glimpse/5/1.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/5/2.jpeg"),
            },
            {
              url: require("@/assets/imgs/about/glimpse/5/3.jpeg"),
            },
          ],
        },
      ],
      actGlimpseIdx: 0,
      isUpdate: true,
      swiperTop: {
        // spaceBetween: 10,
        loop: true,
        loopedSlides: 3,
        loopAdditionalSlides: 3,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
      swiperBottom: {
        spaceBetween: 20,
        slidesPerView: 3,
        touchRatio: 0.2,
        loop: true,
        loopedSlides: 3,
        loopAdditionalSlides: 3,
        slideToClickedSlide: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    params() {
      return this.$route.params;
    },
    query() {
      return this.$route.query;
    },
  },
  created() {
    let that = this;
    that.type = that.params.type;
  },
  mounted() {
    let that = this;
    that.$nextTick(() => {
      const topSwiper = that.$refs.topSwiper.swiper;
      const bottomSwiper = that.$refs.bottomSwiper.swiper;
      topSwiper.controller.control = bottomSwiper;
      bottomSwiper.controller.control = topSwiper;
    });
  },
  methods: {
    terrainSelect(item) {
      let that = this;
      that.actTerrain = item.cover;
    },
    glimpseSelected(idx) {
      let that = this;
      that.actGlimpseIdx = idx;
      that.isUpdate = false;
      that.$nextTick(() => {
        that.isUpdate = true;
        that.$nextTick(() => {
          const topSwiper = that.$refs.topSwiper.swiper;
          const bottomSwiper = that.$refs.bottomSwiper.swiper;
          topSwiper.controller.control = bottomSwiper;
          bottomSwiper.controller.control = topSwiper;
        });
      });
    },
  },
  watch: {
    query(newV) {
      let that = this;
      that.type = newV.type;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  overflow: hidden;
  width: 100%;
  padding-bottom: 120px;
  > .survey {
    width: 1200px;
    margin: 0 auto 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      display: block;
      width: 500px;
      height: 540px;
    }
    > div {
      width: 700px;
      margin-left: -60px;
      border-left: 10px solid #7bdff2;
      padding: 36px 50px;
      background-color: #f4f3f3;
      > img {
        display: block;
        width: 100%;
      }
    }
  }
  > .terrain {
    width: 100%;
    height: 550px;
    background-image: url("~@/assets/imgs/about/terrainBg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1920px 550px;
    padding-top: 48px;
    > div {
      width: 1200px;
      margin: 0 auto;
      > div:first-child {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        > img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
        > h4 {
          font-size: 20px;
          color: #333;
          line-height: 26px;
        }
      }
      > div:last-child {
        display: flex;
        width: 100%;
        justify-content: space-between;
        > ul {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > li {
            width: 380px;
            height: 70px;
            border-left: 6px solid #ccc;
            background: #f4f3f3;
            font-size: 16px;
            color: #999;
            line-height: 70px;
            cursor: pointer;
            position: relative;
            // box-shadow: 0px 0px 10px 0px rgba(210, 210, 210, 0.28);
            > div {
              display: none;
              position: absolute;
              right: -20px;
              top: 50%;
              margin-top: -10px;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-left: 20px solid rgba(123, 223, 242, 0.3);
              border-bottom: 10px solid transparent;
            }
            &.act,
            &:hover {
              border-left-color: #7bdff2;
              background: rgba(123, 223, 242, 0.3);
              color: #444;
              > div {
                display: block;
              }
            }
          }
        }
        > img {
          width: 760px;
          height: 370px;
          display: block;
        }
      }
    }
  }
  > .glimpse {
    width: 100%;
    overflow: hidden;
    background-image: url("~@/assets/imgs/about/glimpseBg.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 1920px 400px;
    padding-top: 70px;
    > h3 {
      font-size: 30px;
      color: #fff;
      line-height: 40px;
      margin-bottom: 50px;
    }
    > ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      > li {
        width: 140px;
        height: 50px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 6px;
        border: 1px solid #ffffff;
        line-height: 48px;
        margin-right: 60px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        &:hover,
        &.act {
          background: rgba(123, 223, 242, 0.4);
          border: 1px solid #7bdff2;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    > div {
      width: 1200px;
      margin: 0 auto 50px;
      padding: 40px 100px 0;
      background: #fff;
      > .topSwiper {
        width: 100%;
        height: 500px;
        margin-bottom: 20px;
      }
      > .bottomSwiperBox {
        width: 100%;
        position: relative;
        padding: 0 36px;
        .next {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          outline: none;
          width: 36px;
          height: 80px;
          margin: 0;
          background-image: url("~@/assets/imgs/about/next.png");
          background-size: 100% 100%;
        }
        .prev {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          outline: none;
          width: 36px;
          height: 80px;
          margin: 0;
          background-image: url("~@/assets/imgs/about/prev.png");
          background-size: 100% 100%;
        }
        > .bottomSwiper {
          width: 100%;
          height: 170px;
        }
      }
      .topSwiperSlide > img,
      .bottomSwiperSlide > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  > .openTime {
    width: 100%;
    height: 550px;
    background-image: url("~@/assets/imgs/about/timeBg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1920px 570px;
    padding-top: 38px;
    margin-bottom: 50px;
    > div {
      width: 1200px;
      margin: 0 auto;
      > div:first-child {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        > img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
        > h4 {
          font-size: 20px;
          color: #fff;
          line-height: 26px;
        }
      }
      > img {
        display: block;
        margin: 0 auto;
        width: 1020px;
        height: 402px;
      }
    }
  }
  > .trafficGuide {
    width: 100%;
    overflow: hidden;
    > div {
      width: 1200px;
      margin: 0 auto;
      > div:first-child {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        > img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
        > h4 {
          font-size: 20px;
          color: #333;
          line-height: 26px;
        }
      }
      > img {
        display: block;
        margin: 0 auto;
        width: 1182px;
        height: 500px;
      }
    }
  }
}
</style>