<template>
    <div class="foot">
        <div class="links">
            <div>友情链接</div>
            <ul>
                <li v-for="(item,index) of links" :key="index" @click="goOutLink(item.url)">{{item.title}}</li>
            </ul>
        </div>
        <div class="companyInfo">
            <div class="info">
                <ul>
                    <li>
                        <p>联系地址：</p>
                        <p> 武汉市江岸区南京路63号</p>
                    </li>
                    <li>
                        <p>联系电话：</p>
                        <p>027-82816233</p>
                    </li>
                    <li>
                        <p>总访问量：</p>
                        <p> {{pvs}}</p>
                    </li>
                    <li>
                        <p>今日访量：</p>
                        <p> {{pv}}</p>
                    </li>
                </ul>
            </div>
            <div class="line"></div>
            <div class="ma">
                <img src="@/assets/imgs/c_foot/foot_ewm.png" alt="">
                <p>扫码关注公众号</p>
            </div>
            <div class="line"></div>
            <div class="company" @click="goOutLink('http://bszs.conac.cn/sitename?method=show&id=12B3F01E295833AFE053022819ACB0E1')">
                <img src="@/assets/imgs/c_foot/foot_jb.png" alt="">
                <p>全国党政机关事业单位</p>
                <p>互联网网站标识</p>
            </div>
        </div>
        <div class="copyright" @click="goOutLink('https://beian.miit.gov.cn/#/Integrated/recordQuery')">版权所有©  江岸区图书馆版权所有  鄂ICP备11013094号-1 </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            links: []
        }
    },
    computed: {
        pvs() {
            return this.$store.state.pvs
        },
        pv() {
            return this.$store.state.pv
        }
    },
    created(){
        let that = this;
        that.getList();
    },
    methods:{
        goOutLink(link) {
            let that = this;
            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);
            window.open(link)
        },
        getList() {
            let that = this;
            that.api.linkList().then((res)=>{
                that.links = res.data.data;
            })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
};
</script>
<style lang="scss" scoped>
    .foot{
        width: 100%;
        position: relative;
        height: 364px;
        background-color: #21202A;
        padding-top: 124px;
        >.links{
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%,-50%);
            width: 1200px;
            background-color: #fff;
            box-shadow: 2px -8px 10px 4px rgba(206, 206, 206, 0.16);
            display: flex;
            >div{
                width: 140px;
                background-color: #7BDFF2;
                color: #fff;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            >ul{
                width: 0%;
                flex: 1;
                overflow: hidden;
                padding: 18px 50px;
                >li{
                    float: left;
                    font-size: 14px;
                    color: #666;
                    margin-right: 30px;
                    line-height: 34px;
                    cursor: pointer;
                }
            }
        }
        >.companyInfo{
            height: 180px;
            width: 1200px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            >.info{
                overflow: hidden;
                >ul{
                    overflow: hidden;
                    >li{
                        overflow: hidden;
                        color: #666;
                        font-size: 14px;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        >p:first-child{
                            width: 150px;
                        }
                    }
                }
            }
            >.line{
                width: 1px;
                height: 133px;
                margin: 0 62px;
                background: linear-gradient(to bottom, rgba(200, 200, 200, 0), rgba(102, 102, 102, 1), rgba(151, 151, 151, 0));
            }
            >.ma,
            >.company{
                overflow: hidden;
                position: relative;
                >img{
                    position: relative;
                    left: 0;
                    top: 0;
                    right: 0;
                    width: 100px;
                    height: 100px;
                    margin: 0 auto 14px;
                }
                >p{
                    color: #666;
                    font-size: 14px;
                    line-height: 30px;
                }
            }
            >.company{
                cursor: pointer;
                >img{
                    width: 81px;
                }
            }
        }
        >.copyright{
            width: 100%;
            height: 60px;
            background-color: #111115;
            font-size: 14px;
            color: #666;
            line-height: 60px;
            cursor: pointer;
        }
    }
</style>
