<template>
    <div class="container">
        <c-banner :type="type" :bannerHeight="'600px'" />
        <div class="main">
            <p class="navTitle" style="text-algin:left">
                <img src="../assets/imgs/home/from.png" alt="">
                您当前的位置是 : 网站首页&nbsp;>&nbsp;{{this.$store.state.navTitle}}&nbsp;>&nbsp;
                    <span v-if="classList[actIndex]">
                        {{classList[actIndex].title}}
                    </span>
            </p>
            <div>
                <div class="left">
                <ul>
                        <li v-for="(item , index) of classList" :key="item.id" :class="[actId == item.id ? 'act' : '']" @click="goClass(item , index)">
                            <p>{{item.title}}</p>
                        </li>
                    </ul>
                </div>
                <div class="right">
                    <literatureOrAffairsList :actId="actId" :actName="actName" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import literatureOrAffairsList from '@/containers/literatureOrAffairs/literatureOrAffairsList'
import cBanner from "@/components/c-banner";
export default {
    components: {
        literatureOrAffairsList,
        cBanner
    },
    data() {
        return {
            classList: [],
            type: '',
            actId: 1,
            actName: '',
            actIndex:0
        }
    },
    computed: {
        // params() {
        //     return this.$route.params
        // },
        query() {
            return this.$route.query
        }
    },
    created() {
        let that = this;
        that.type = that.query.type;
        // console.log(this.type);
        if(that.type == 'affairs') {
            that.getBulletinTypes();
        }else if(that.type == 'literature') {
            that.getLiteratureTypes();
        }
    },
    mounted() {
        // console.log(1);
        let that = this;
    },
    methods: {
        //政务公开
        getBulletinTypes() {
            let that = this;
            that.api.bulletinTypes().then((res)=>{
                // console.log(res.data.data);
                that.classList = res.data.data;
                // if(that.query.id) {
                //     that.actId = that.query.id;
                //     for(let i of that.classList) {
                //         if( that.query.id == i.id ) {
                //             that.actName = i.title
                //         }
                //     }
                // }else{
                    that.actId = that.classList[0].id;
                    that.actId = that.classList[0].id;
                    that.actName = that.classList[0].title
                // }
                console.log(that.actId);
            })
        },
        //地方文献
        getLiteratureTypes() {
            let that = this;
            that.api.literatureTypes().then((res)=>{
                that.classList = res.data.data;
                that.actId = that.classList[0].id;
                // console.log(this.actId , 'sss');
                // if(that.query.id) {
                //     // that.actId = that.query.id;
                //     for(let i of that.classList) {
                //         if( that.query.id == i.id ) {
                //             that.actName = i.title
                //         }
                //     }
                // }else{
                    that.actId = that.classList[0].id;
                    that.actName = that.classList[0].title
                    // console.log(that.actId);
                // }
            })
        },
        goClass(item , index) {
            // console.log(item, this.actId);
            let that = this;
            that.actIndex = index
            that.actId = item.id
            that.actName = item.title
            // console.log(that.actName);
            let staticData = {
                click: true,
                pv: false,
                search: true
            }
            // console.log(1231231 , item);
            that.addStatic(staticData);
            // // console.log(item);
            // console.log(this.$route.query);

            // that.$router.push({
            //     path: '/' + this.$route.query.type,
            //     // params: that.params,
            //     query: {
            //         id: item.id,
            //         type:this.$route.query.type
            //     }
            // })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    },
    watch: {
        // params(newV) {
        //     let that = this;
        //     that.type = newV.type;
        //     if(that.type == 'affairs') {
        //         that.getBulletinTypes();
        //     }else if(that.type == 'literature') {
        //         that.getLiteratureTypes();
        //     }
        // },
        query(newV) {
            // console.log(newV);
            let that = this;
            this.actId = 1
            that.type = newV.type;
            if(that.type == 'affairs') {
                that.getBulletinTypes();
            }else if(that.type == 'literature') {
                that.getLiteratureTypes();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    .container{
        overflow: hidden;
        width: 100%;
        background: #F7F7F7;
        padding-bottom: 120px;
        >.main{
            width: 1200px;
            margin: 0 auto;
            >p{
                text-align: left;
            }
            >div{
                display: flex;
                >.left{
                    position: relative;
                    width: 200px;
                    margin-right: 20px;
                    overflow: hidden;
                    >ul{
                        position: relative;
                        left: 0;
                        top: 0;
                        width: 100%;
                        background: #fff;
                        text-align: left;
                        >li{
                            cursor: pointer;
                            padding: 0 20px;
                            height: 60px;
                            border-left: 4px solid #fff;
                            >p{
                                width: 100%;
                                border-bottom: 1px solid #E5E5E5;
                                height: 60px;
                                font-size: 16px;
                                color: #333;
                                line-height: 59px;
                            }
                            &:hover,
                            &.act{
                                border-left: 4px solid #7BDFF2;
                                background: #E7F3F5;
                                >p{
                                    color: #7BDFF2;
                                }
                            }
                            &:last-child{
                                >p{
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
                >.right{
                    width: 0%;
                    flex: 1;
                    background: #fff;
                }
            }

        }
    }
    .navTitle{
        font-size: 14px;
        // font-family: MicrosoftYaHei;
        color: #666666;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        >img{
            width: 16px;
            height: 17px;
            margin-right: 10px;
        }
        >span:last-child{
            font-weight: 600;
            color: #222222;
        }
    }
    
</style>